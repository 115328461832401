const messages = {
  a_few_more_details: 'Een paar gegevens nog',
  accept_mailinglist_sentence: 'Ik meld mij aan voor de e-mailings van Thermen {resort} met nieuws en persoonlijke aanbiedingen',
  accept_retreats_mailinglist_sentence: 'Ik meld mij aan voor de e-mailings van Retreats met nieuws en persoonlijke aanbiedingen',
  accept_terms_sentence: 'Hierbij bevestig ik akkoord te gaan met onderstaande voorwaarden; {terms_list}',
  accept_thermenfans_sentence: 'Ik meld mij aan voor het {link} en profiteer daardoor van exclusieve voordelen zoals: aanbiedingen, gepersonaliseerde kortingsvouchers, leuke cadeaus en exclusieve toegang tot evenementen',
  add_one: 'Voeg toe',
  all_choices: 'Alle keuzes',
  amount_of_rooms: '{n} kamer | {n} kamers',
  an_email_will_be_sent_to_your_email_if_you_press_reset_password_this_email_will_contain_a_link_to_request_a_new_password: 'Er zal een e-mail verstuurd worden naar jouw email indien je op reset wachtwoord drukt. In deze mail zal een link staan om een nieuw wachtwoord aan te vragen',
  are_you_coming_together_but_still_want_to_enjoy_your_own_room: 'Kom je samen, maar wil je toch genieten van een eigen kamer? Geen probleem. Geef eenvoudig je voorkeur aan tijdens het boeken en wij zorgen ervoor dat alles naar wens is geregeld. Zo beleeft ieder zijn eigen ultieme moment van ontspanning.',
  are_you_sure_you_want_to_reset_your_password: 'Weet je zeker dat je je wachtwoord wilt resetten?',
  arrangement_choice_nr: 'Arrangement keuze {n}',
  arrangement_choices: 'Arrangement @:choices',
  arrival: 'Aankomst',
  arrival_and_departure_time_explanation: 'Voor een ontspannen bezoek, vragen we je aan te komen en te vertrekken op de tijden van je reservering. Zó houden we samen een fijne balans in het aantal gasten dat bij ons in- en uitcheckt.',
  belgium: 'Belgium',
  business_name: 'Bedrijfsnaam',
  cancellation_policy: 'Annuleringsvoorwaarden',
  choices: 'keuze | keuzes',
  choose_arrangement: 'Maak het echt jouw arrangement. Kies uit de onderstaande opties je favoriet voor jouw wellnessdag.',
  choose_your_arrival_and_departure_day_in_the_calendar: 'Kies in de kalender jouw aankomst- en vertrekdag',
  choose_your_type_of_entrance: 'Kies jouw type entree',
  city: 'Plaats',
  close: 'Sluiten',
  comments_or_extra_information: 'Opmerkingen / extra informatie',
  compare_etb_comment: 'Dit gedeelte is alleen gekleed toegankelijk. Badkleding is verplicht.',
  compare_etb_title: 'Thermaalbad entree',
  compare_etb_usp_1: 'Toegang tot twee thermaalbaden',
  compare_etb_usp_2: 'Toegang tot ons stoombadhuis, de parkserre, rustruimtes met lavendelkamer',
  compare_etb_usp_3: 'Toegang tot de ligweides en het badjassenpark',
  compare_etb_usp_4: 'Toegang tot Restaurant Bubbels',
  compare_etbs_comment: 'Dit gedeelte is ongekleed toegankelijk. Alléén op badkledingdag draag je badkleding.',
  compare_etbs_title: 'Thermaalbad + sauna entree',
  compare_etbs_usp_1: 'Toegang tot alle saunafaciliteiten (meer dan 10 sauna’s)',
  compare_etbs_usp_2: 'Toegang tot ál onze thermaalbaden',
  compare_etbs_usp_3: 'Toegang tot ons stoombadhuis, de parkserre, rustruimtes met lavendelkamer',
  compare_etbs_usp_4: 'Toegang tot de ligweides en het badjassenpark',
  compare_etbs_usp_5: 'Toegang tot Restaurant Bubbels en de Sauna lodge',
  compare_termal_comment: 'Omdat we weten dat iedereen ontspanning anders ervaart, hebben we twee verschillende zones gecreëerd in ons resort. Boek je een thermaalbad entree óf een entree thermaalbad incl. sauna gedeelte? Kijk waar jij van gaat genieten:',
  compare_termal_title: 'Entree thermaalbad en entree thermaalbad + sauna',
  country: 'Land',
  customer_page_into_sentence: 'Nog een beetje informatie hebben we van je nodig. Echt een beetje. En natuurlijk houden we jouw gegevens voor onszelf. Beloofd.',
  date_of_birth: 'Geboortedatum',
  day: 'Dag',
  departure: 'Vertrek',
  edit: "Wijzigen",
  email_address: 'E-mailadres',
  estimated_time_of_arrival: 'Verwachte aankomsttijd',
  estimated_time_of_departure: 'Verwachte vertrektijd',
  evening: 'Avond',
  extra_charge: 'Meerprijs',
  first_name: 'Voornaam',
  fold: "Inklappen",
  for_who: 'Voor wie?',
  forgot_your_password_no_problem: 'Wachtwoord vergeten? Geen probleem.',
  from_price: "v.a.",
  germany: 'Duitsland',
  go_to_the_website: 'Naar de website',
  hide_all_single_rooms: 'Verberg alle eenpersoonskamers',
  hotel_room_availability: 'Nog {number} beschikbaar',
  hotel_room_choice: 'Hotelkamer keuze',
  hotel_room_type_1: 'eenpersoonskamer',
  hotel_room_type_2: 'tweepersoonskamer',
  hotel_room_type_3: 'driepersoonskamer',
  hotel_room_type_4: 'vierpersoonskamer',
  hotel_rooms: 'Hotelkamers',
  hotel_rooms_page_into_sentence: 'Komt je bij ons overnachten?',
  house_number: 'Huisnummer',
  house_number_addition: 'Toev.',
  house_rules: 'huisregels',
  how_long: 'Hoe lang?',
  how_would_you_like_to_book_your_relaxing_moment: 'Hoe wil jij je ontspanmoment boeken?',
  i_have_a_my_thermen_account: 'Ik heb een Thermen Resorts account',
  i_want_to_create_a_my_thermen_account: 'Ik wil een Thermen Resorts account aanmaken',
  i_want_to_create_a_my_thermen_account_tooltip: 'Sneller reserveren en nooit meer je gegevens in hoeven vullen. Een Thermen Resorts account biedt je meer. Meer gemak én meer voordelen. Let op! Jouw account is pas geactiveerd door te klikken op de link in de verificatie e-mail.',
  i_want_to_make_a_reservation_without_an_account: 'Ik wil reserveren zonder account',
  i_would_like_to_create_an_account: 'Ik wil graag een account aanmaken',
  invoice_comment_placeholder: "Opmerkingen/bijzonderheden (bijvoorbeeld inkoopnummer)",
  invoice_information: "Factuurgegevens werkgever",
  last_name: 'Achternaam',
  login: 'Inloggen',
  logout: 'Uitloggen',
  lunch_and_diner: 'Lunch en diner',
  lunch_and_diner_page_into_sentence: 'Jouw wellnessdag nog completer en nóg fijner? Ontdek onze opgietingen, rituelen, meditaties en andere fijne ontspanmomenten... Onze tip? Kies vooral waar je blij van wordt!',
  make_it_your_day: 'Maak het helemaal jouw dag!',
  mobile_phone_number: 'Mobiel telefoonnummer',
  month: 'Maand',
  more_info: 'Meer info',
  more_information: 'Meer informatie',
  morning: 'Ochtend',
  mr: 'De heer',
  ms: 'Mevrouw',
  name_infix: 'Tussenv.',
  next: 'Volgende',
  no_extra_charge: 'Geen meerprijs',
  notice_chosen_date_or_time_is_unavailable: 'De gekozen datum en/of tijd is niet beschikbaar!',
  notice_there_are_no_hotel_rooms_unavailable: 'Er zijn geen kamers beschikbaar op de gekozen datum.',
  notice_this_hotel_room_is_unavailable: 'Deze kamer is op de gekozen dag niet meer beschikbaar!',
  number_of_persons: '{n} persoon | {n} personen',
  or_stay_with_us_for_a_good_night_sleep: 'Of blijf je lekker slapen?',
  other: 'Anders',
  password: 'Wachtwoord',
  password_confirmation: 'Herhaal wachtwoord',
  pay: 'Betalen',
  per_night: 'per nacht',
  per_person_per_night: 'p.p.p.n.',
  per_person_including_breakfast_starting_from: 'p.p. inclusief ontbijt al vanaf',
  per_person_short: 'p.p.',
  persons: 'persoon | personen',
  persons_count: 'Aantal personen',
  previous: 'Vorige',
  prices_are_in_euro: 'Prijzen in € @:per_person_short',
  privacy_policy: 'Privacy Policy',
  receive_a_present_on_your_birthday: 'Cadeautje ontvangen op je verjaardag?',
  reload: 'Herladen',
  request_your_new_password_here: 'Vraag hier jouw nieuwe wachtwoord aan.',
  reset: 'Herinitialiseren',
  reset_password: 'Reset wachtwoord',
  rituals_extras: 'Extra\'s op jouw dagje sauna',
  rituals_extras_page_into_sentence: 'Jouw wellnessdag nog completer en nóg fijner? Ontdek onze opgietingen, rituelen, meditaties en andere fijne ontspanmomenten... Onze tip? Kies vooral waar je blij van wordt!',
  rooms_count: 'Aantal kamers',
  select: 'Selecteer',
  select_all: 'Selecteer alles',
  select_for_all: 'Selecteer voor iedereen',
  selected: 'Geselecteerd',
  send_invoice: 'Mijn werkgever betaalt de factuur.',
  share_a_room_together_select_one_of_the_available_room_options_below: 'Deel samen een kamer, selecteer hieronder één van de beschikbare kameropties.',
  show_all_single_rooms: 'Toon alle eenpersoonskamers',
  something_went_wrong_while_retrieving_your_data: 'Er ging iets mis bij het ophalen van uw gegevens.',
  steps: {
    choices: 'Jouw keuzes',
    complete: 'Afronden',
    customer_details: 'Gegevens',
    reservation: 'Reservering',
  },
  street: 'Straat',
  swimwear_day: 'Badkledingdag',
  swimwear_day_tooltip_sentence: 'De blauw gemarkeerde dagen in de kalender zijn badkledingdagen. Het dragen van badkleding is op deze dagen verplicht.',
  terms_and_conditions: 'Algemene voorwaarden',
  terms_item_1: 'Mijn aankomst- en vertrektijd (zodat evt. behandelingen tussen een half uur na de opgegeven aankomsttijd en voor de opgegeven vertrektijd kunnen plaatsvinden)',
  terms_item_2: 'De {house_rules} heb gelezen',
  terms_item_3: 'De {terms_and_conditions}, {cancellation_policy} en {privacy_policy}',
  the_difference: 'Het verschil',
  the_netherlands: 'Nederland',
  there_are_no_selectable_products_available_at_the_selected_time: 'Er zijn op het geselecteerde moment geen selecteerbare producten beschikbaar.',
  thermenfans_program: 'Thermenfans-programma',
  total: 'Totaal',
  treatment_page_into_sentence: 'Jouw dag bij ons kan nog completer. Een massage? Een behandeling..? Onze tip: kies waar je blij van wordt, dat is altijd de beste keuze.',
  unavailable: 'Niet beschikbaar',
  view_reservation: 'Bekijk reservering',
  we_put_together_the_available_options_for_you: 'We stellen de beschikbare opties voor je samen...',
  what_is_the_difference: 'Wat is het verschil?',
  what_would_you_like_to_book: 'Wat wil je reserveren',
  when_are_you_coming_to_relax: 'Wanneer kom je ontspannen?',
  when_resetting_previously_entered_data_will_be_lost: 'Bij het resetten gaan eerder ingevoerde gegevens verloren.',
  which_type_of_treatment_suits_you: 'Welke behandeling past bij jou?',
  year: 'Jaar',
  you_have: 'Je hebt',
  you_have_number_of_choices: 'Je hebt <strong>{n}</strong> @:choices',
  you_want_to_book_with: 'Je wilt boeken met <strong>{persons}</strong> en wilt <strong>{rooms}</strong> met aankomstdatum {arrival_date} en vertrekdatum {departure_date}.',
  your_experience: 'Dit ga je beleven:',
  your_first_step_to_great_enjoyment: 'Je eerste stap naar groots genieten!',
  zip_code: 'Postcode',
};

// Export the shape of the dutch translations to use in
// other language files to ensure we have fill in all translation keys.
export type MessageSchema = typeof messages;

export default messages;
