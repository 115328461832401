import { z } from 'zod';
import { personalDataResource } from '@/api/resources';

export const createCartRequest = z.object({
  checkout_id: z.string().nullable().optional(),
  product_id: z.number().optional(),
  voucher_codes: z.array(z.string()).nullable().optional(),
  bath_card_id: z.number().nullable().optional(),
  persons_count: z.string().nullable().optional(),
  amount_of_rooms: z.string().nullable().optional(),
  arrival: z.object({
    date: z.string(),
    time: z.string().optional(),
  }).optional(),
  departure: z.object({
    date: z.string(),
    time: z.string().optional(),
  }).optional(),
  checkout_type: z.string().nullable().optional(),
  ga_origin: z.string().nullable().optional(),
});

export const createBookingRequest = z.object({
  personal_data: personalDataResource,
  terms: z.boolean(),
  comment: z.string().nullable(),
  authentication: z.object({
    action: z.enum(['guest', 'register', 'login']),
    password: z.string().nullable(),
    password_confirmation: z.string().nullable(),
  }),
  user_agent: z.string().nullable(),
  screen_resolution: z.string().nullable(),
  browser_language: z.string().nullable(),
  token_cookie: z.string().nullable(),
  session_cookie: z.string().nullable(),
  global_session_cookie: z.string().nullable()
});

export type CreateCartRequest = z.infer<typeof createCartRequest>;
export type CreateBookingRequest = z.infer<typeof createBookingRequest>;
