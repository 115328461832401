<template>
  <div>
    <div class="pb-6 mb-6 border-b border-b-gray-200">
      <h1>{{ $flowstore.checkout.meta.text.reservation.title }}</h1>
      <p v-if="$flowstore.checkout.meta.text.reservation.description !== ''">
        {{ $flowstore.checkout.meta.text.reservation.description }}</p>
    </div>

    <template v-if="flowStore.comparableCheckoutTypes.length >= 2">
      <CompareCheckoutTypeModal ref="compareCheckoutTypeModal" :checkoutTypes="flowStore.comparableCheckoutTypes"/>

      <div class="flex flex-wrap justify-between items-center">
        <h2 class="text-lg font-sans font-normal m-0">{{ $t('choose_your_type_of_entrance') }}</h2>
        <button @click="compareCheckoutTypeModal?.openModal()" type="button"
                class="uppercase text-sm font-semibold flex gap-2 items-center">
          <HelpCircle class="w-3.5"/>
          {{ $t('the_difference') }}
        </button>
      </div>

      <div class="grid lg:grid-cols-2 gap-6 my-6">
        <CheckoutTypeCard v-for="checkoutType in flowStore.comparableCheckoutTypes" :key="checkoutType.type"
                          :checkoutType="checkoutType"
                          :active="$flowstore.checkout.data.checkout_type === checkoutType.type"/>
      </div>
    </template>

    <div v-if="flowStore.simpleCheckoutTypes.length" class="grid grid-cols-2 gap-4 mb-4">
      <label class="flex items-center">{{ $t('what_would_you_like_to_book') }}</label>
      <SelectField variant="outlined" :optional="false" v-model="arrangement" :options="flowStore.simpleCheckoutTypes.map(entry => ({
        value: entry.type,
        label: entry.name,
      }))"/>
    </div>

    <div :class="['grid 2xs:grid-cols-2 gap-4 mb-4', {'pb-6 mb-6 border-b border-b-gray-200': !flowStore.isHotelFlow || !flowStore.checkout.meta.show_hotel_room_categories}]">
      <label class="flex items-center">{{ $t('persons_count') }} </label>
      <IncrementNumberField :modelValue="$flowstore.checkout.data.persons_count"
                            @update:modelValue="flowStore.setPersonsCount($event)"
                            :min="$flowstore.checkout.meta.person_min" :max="$flowstore.checkout.meta.person_max"
                            label="number_of_persons" data-cy="number_of_persons"/>
    </div>

    <div class="grid 2xs:grid-cols-2 gap-4 pb-6 mb-6 border-b border-b-gray-200" v-if="flowStore.isHotelFlow && flowStore.checkout.meta.show_hotel_room_categories">
      <label class="flex items-center gap-2">
        {{ $t('rooms_count') }}
        <Tooltip align="left">
          <div v-html="$t('are_you_coming_together_but_still_want_to_enjoy_your_own_room')" />
        </Tooltip>
      </label>
      <IncrementNumberField :modelValue="$flowstore.checkout.data.amount_of_rooms"
                            @update:modelValue="flowStore.setRoomsCount($event)"
                            :min="1" :max="$flowstore.checkout.data.persons_count"
                            label="amount_of_rooms" data-cy="amount_of_rooms"/>
    </div>

    <Transition name="fade">
      <div v-if="flowStore.checkout.calendar_notifications_above">
        <NoteBlock v-for="notice in flowStore.checkout.calendar_notifications_above" class="my-6" variant="notice">
          <HtmlContent :html="notice"/>
        </NoteBlock>
      </div>
    </Transition>

    <h3 class="text-lg font-sans font-normal mb-7 flex justify-between items-center gap-6">
      {{ flowStore.isHotelFlow ? $t('choose_your_arrival_and_departure_day_in_the_calendar') : $t('when_are_you_coming_to_relax') }}

      <div v-if="flowStore.isHotelFlow" class="flex justify-end gap-2">
        <span :class="{
          'inline-flex items-center justify-center h-6 border rounded-full px-2 text-xs min-w-[3.55rem]': true,
          'bg-primary border-primary text-primary-letter': startDate,
          'bg-transparent border-black': !startDate,
        }">{{ startDate ? format(startDate, 'd LLL', {locale}) : $t('arrival') }}</span>
        <span :class="{
          'inline-flex items-center justify-center h-6 border rounded-full px-2 text-xs min-w-[3.55rem]': true,
          'bg-primary border-primary text-primary-letter': endDate && endDateIsAfterStart,
          'bg-transparent border-black': !endDate || !endDateIsAfterStart,
        }">{{ endDate && endDateIsAfterStart ? format(endDate, 'd LLL', {locale}) : $t('departure') }}</span>
      </div>
    </h3>

    <div v-if="flowStore.entreeProducts.length > 1" class="flex flex-wrap gap-2 mb-8">
      <Button v-for="entreeProduct in flowStore.entreeProducts" :key="entreeProduct.key"
              @click="changeMainProduct(entreeProduct.product_id)" variant="light"
              :active="flowStore.checkout.data.product_id === entreeProduct.product_id">
        {{ $t(entreeProduct.key) }}
      </Button>
    </div>

    <Transition name="fade">
      <NoteBlock v-if="$validationstore.errors.departure_date" variant="error" class="my-6 error-scroll-anchor">
        {{ $validationstore.errors.departure_date[0] }}
      </NoteBlock>
    </Transition>

    <Card class="relative">
      <LoadingOverlay v-if="$loadingstore.changing_main_product"/>
      <Datepicker
        :startDate="startDate"
        :endDate="endDate"
        :dateOptions="calendarData"
        :language="config.language"
        selectedColor="hsl(var(--primary), 37%)"
        selectedTextColor="#ffffff"
        hoverTextColor="#ffffff"
        hoverColor="hsla(var(--primary), 37%, 0.5)"
        :range="flowStore.isHotelFlow"
        :staticRange="flowStore.checkout.meta.exact_number_of_nights ?? undefined"
        @month:change="({event, focus, dates}) => loadCalendarData(focus.getFullYear(), focus.getMonth() + 1)"
        @day:change="changeCalendarDays"
      >
        <template #day="day">
          <div class="font-bold text-sm 2xl:text-base">
            {{ day.raw.getDate() }}
          </div>
          <div v-if="day.options.notifications?.length"
               class="absolute -right-3 sm:right-0 -top-3 sm:top-0 transition-[top]">
            <Tooltip align="left">
              <template #toggle>
                <span class="flex justify-start items-end h-5 w-5">
                  <InfoCircle class="w-3.5 drop-shadow"/>
                </span>
              </template>
              <HtmlContent v-for="(notification, i) in day.options.notifications" :key="i" :html="notification"
                           :class="{ 'mt-4 pt-4 border-t border-qwr-haze-100': i > 0 }"/>
            </Tooltip>
          </div>
          <!--
            Only show the price at the date selection if the day is available (active), has a price and is not the last
            day in the range (in case there is a date range). Because in case of hotel arrangements the last night is never billed
            OR
            the price is overwritten in case exact nights is enabled, in that case do show the price at the selected arrival date
          -->
          <Price
            v-if="(!day.options.inactive && day.options.price && !day.options.lastDayInRange) || flowStore.checkout.meta.overwrite_fixed_price_for_exact_nights"
            :value="
              (flowStore.checkout.meta.overwrite_fixed_price_for_exact_nights && flowStore.checkout.data.arrival.date === day.options.date)
                ? flowStore.checkout.meta.overwrite_fixed_price_for_exact_nights
                : day.options.price"
            class="text-xs leading-4"
          />
        </template>
      </Datepicker>
      <div class="flex justify-between items-center text-xs p-5 pt-0 min-h-10">
        <div>
          <Tooltip align="left" v-if="showSwimwearDayInfo">
            <template #toggle>
              <div class="text-blue-500 flex gap-2 items-center font-semibold">
                <InfoCircle class="w-3.5" /> {{ $t('swimwear_day') }}
              </div>
            </template>
            {{ $t('swimwear_day_tooltip_sentence') }}
          </Tooltip>
        </div>
        <div>{{ $t('prices_are_in_euro') }}</div>
      </div>
    </Card>

    <Transition name="fade">
      <div v-if="flowStore.checkout.calendar_notifications">
        <NoteBlock v-for="notice in flowStore.checkout.calendar_notifications" class="my-6">
          <HtmlContent :html="notice"/>
        </NoteBlock>
      </div>
    </Transition>

    <Transition name="fade">
      <div v-if="flowStore.checkout.notices">
        <NoteBlock v-for="notice in flowStore.checkout.notices" variant="error" class="my-6">
          {{ notice[0] }}
        </NoteBlock>
      </div>
    </Transition>

    <div class="grid 2xs:grid-cols-2 gap-4 items-center mt-6">
      <label for="arrivalTime" class="pr-4">
        {{ $t('estimated_time_of_arrival') }}
        <Tooltip class="ml-2">{{ $t('arrival_and_departure_time_explanation') }}</Tooltip>
      </label>
      <SelectTimeField align="center" variant="outlined" id="arrivalTime" data-cy="select-arrival-time"
                       :disabled="$loadingstore.changing_date" :times="$flowstore.checkout.meta.arrival_times"
                       :errors="$validationstore.errors.arrival_time"
                       :modelValue="$flowstore.checkout.data.arrival.time"
                       @update:modelValue="flowStore.setCheckoutTime('arrival', $event)"/>
    </div>

    <div class="grid 2xs:grid-cols-2 gap-4 items-center mt-4">
      <label for="departureTime" class="pr-4">
        {{ $t('estimated_time_of_departure') }}
        <Tooltip class="ml-2">{{ $t('arrival_and_departure_time_explanation') }}</Tooltip>
      </label>
      <SelectTimeField align="center" variant="outlined" id="departureTime" data-cy="select-departure-time"
                       :disabled="$loadingstore.changing_date" :times="$flowstore.checkout.meta.departure_times"
                       :errors="$validationstore.errors.departure_time"
                       :modelValue="$flowstore.checkout.data.departure.time"
                       @update:modelValue="flowStore.setCheckoutTime('departure', $event)"/>
    </div>

    <Transition name="fade">
      <NoteBlock v-if="$validationstore.errors.arrival_date_and_time" variant="error" class="my-6 error-scroll-anchor">
        {{ $validationstore.errors.arrival_date_and_time[0] }}
      </NoteBlock>
    </Transition>

    <Transition name="fade">
      <NoteBlock v-if="$validationstore.errors.product_choices" variant="error" class="my-6 error-scroll-anchor">
        {{ $validationstore.errors.product_choices[0] }}
      </NoteBlock>
    </Transition>

    <Transition name="fade">
      <NoteBlock v-if="$validationstore.errors.product_id" variant="error" class="my-6 error-scroll-anchor">
        {{ $validationstore.errors.product_id[0] }}
      </NoteBlock>
    </Transition>

    <div v-if="arrangementRoomProducts && arrangementRoomProducts.length > 0"
         class="pt-6 mt-6 border-t border-b-gray-200">
      <h2 class="text-2xl font-serif">
        {{ $flowstore.checkout.meta.text.reservation_room_products.title }}
      </h2>
      <p class="text-lg font-sans font-thin mb-7">
        {{ $flowstore.checkout.meta.text.reservation_room_products.description }}
      </p>

      <Transition name="fade">
        <NoteBlock v-if="$validationstore.errors['arrangement_choices.room_products']" variant="error"
                   class="my-6 error-scroll-anchor">
          {{ $validationstore.errors['arrangement_choices.room_products'][0] }}
        </NoteBlock>
      </Transition>
      <HotelRoomChoices :personsCount="$flowstore.checkout.data.persons_count" :products="arrangementRoomProducts"/>
    </div>

    <div v-if="arrangementRoomCategories && arrangementRoomCategories.length > 0" class="pt-6 mt-6 border-t border-b-gray-200">
      <h2 class="text-2xl font-serif">
        {{ $flowstore.checkout.meta.text.reservation_room_products.title }}
      </h2>
      <p class="text-lg font-sans font-thin">
        {{ $flowstore.checkout.meta.text.reservation_room_products.description }}
      </p>

      <div class="font-sans py-7">
        <span v-html="$t('you_want_to_book_with', {
        persons: $t('number_of_persons', $flowstore.checkout.data.persons_count),
        rooms: $t('amount_of_rooms', $flowstore.checkout.data.amount_of_rooms),
        arrival_date: format(startDate, 'dd MMMM yyyy', {locale}),
        departure_date: format(endDate? endDate : startDate, 'dd MMMM yyyy', {locale})
      })" />
        <span class="ml-1 font-bold underline cursor-pointer" @click="moveToElement('[data-cy=number_of_persons]')">{{ $t('edit') }}</span>
      </div>

      <Transition name="fade">
        <NoteBlock v-if="$validationstore.errors['arrangement_choices.room_products']" variant="error"
                   class="my-6 error-scroll-anchor">
          {{ $validationstore.errors['arrangement_choices.room_products'][0] }}
        </NoteBlock>
      </Transition>
      <HotelRoomCategoryChoices :personsCount="$flowstore.checkout.data.persons_count"
                                :categories="arrangementRoomCategories"/>
    </div>

    <div v-if="primaryProducts && primaryProducts.length > 0 || secondaryProducts && secondaryProducts.length > 0"
         class="pt-6 mt-6 border-t border-b-gray-200">
      <h2 class="text-2xl font-serif">
        {{ $flowstore.checkout.meta.text.reservation_primary_products.title }}
      </h2>
      <p class="text-lg font-sans font-thin mb-7">
        {{ $flowstore.checkout.meta.text.reservation_primary_products.description }}
      </p>
      <ArrangementChoices v-if="primaryProducts" group="primary_arrangement" :choice="1" :products="primaryProducts"
                          :errors="$validationstore.errors['arrangement_choices.primary_products']"/>
      <ArrangementChoices v-if="secondaryProducts" group="secondary_arrangement" :choice="2"
                          :products="secondaryProducts"
                          :errors="$validationstore.errors['arrangement_choices.secondary_products']"/>
    </div>
  </div>
</template>

<script lang="ts" setup>
import config, {dateLocale as locale} from '@/config';
import {computed, nextTick, onMounted, ref, watch} from 'vue';
import {format, isAfter, parse} from 'date-fns';
import {useFlowStore} from '@/stores/useFlowStore';
import api from '@/api';
import Button from '@/components/Button.vue';
import Card from '@/components/Card.vue';
import Datepicker, {type DateOptions} from '@/components/datepicker/Datepicker.vue';
import NoteBlock from '@/components/NoteBlock.vue';
import IncrementNumberField from '@/components/fields/IncrementNumberField.vue';
import Price from '@/components/Price.vue';
import SelectTimeField from '@/components/fields/SelectTimeField.vue';
import LoadingOverlay from '@/components/LoadingOverlay.vue';
import {type CalendarResource} from '@/api/resources';
import ArrangementChoices from '@/components/ArrangementChoices.vue';
import HotelRoomChoices from '@/components/HotelRoomChoices.vue';
import HotelRoomCategoryChoices from '@/components/HotelRoomCategoryChoices.vue';
import Tooltip from '@/components/Tooltip.vue';
import HelpCircle from 'thermen-theme/icons/help-circle.svg?component';
import InfoCircle from 'thermen-theme/icons/info-circle.svg?component';
import {useValidationStore} from '@/stores/useValidationStore';
import SelectField from '@/components/fields/SelectField.vue';
import CheckoutTypeCard from '@/components/CheckoutTypeCard.vue';
import CompareCheckoutTypeModal from '@/components/modals/CompareCheckoutTypeModal.vue';
import HtmlContent from '@/components/HtmlContent.vue';
import {isIntergrated, sendMessage} from "@/lib/comm";

const flowStore = useFlowStore();
const validationStore = useValidationStore();

const compareCheckoutTypeModal = ref<InstanceType<typeof CompareCheckoutTypeModal>>();
const calendarData = ref<DateOptions>({});
const arrangement = ref('sauna-entree');
const showSwimwearDayInfo = ref(false);

watch(arrangement, (value) => {
  if (value !== 'sauna-entree') {
    const checkoutType = flowStore.simpleCheckoutTypes.find((entry) => entry.type === value);
    if (checkoutType) {
      sendMessage('reservationflow', 'redirect', {tag: 'FLOW_CHANGE', url: checkoutType.redirect_url});
      sendMessage('reservationflow', 'reservationflow.close');

      if (!isIntergrated) window.location.href = checkoutType.redirect_url;
    }
  }
});

watch(() => validationStore.errors, async () => {
  await nextTick();

  const scrollAnchors = Array.from(document.querySelectorAll<HTMLElement>('.error-scroll-anchor'))
  .filter(el => !('_isLeaving' in el)); // Filter out elements that are animated out. NOTE: Hacky internal VueJS thing

  if (scrollAnchors.length > 0) scrollAnchors[0].scrollIntoView({behavior: 'smooth', block: 'center'});
});

const primaryProducts = computed(() => flowStore.checkout.meta.arrangement_choices?.primary_products.filter(product => product.is_available || !product.hide_when_not_available));
const secondaryProducts = computed(() => flowStore.checkout.meta.arrangement_choices?.secondary_products.filter(product => product.is_available || !product.hide_when_not_available));
const arrangementRoomProducts = computed(() => flowStore.checkout.meta.arrangement_choices?.room_products.filter(product => {
  return !('products' in product) && (product.is_available || !product.hide_when_not_available);
}));
const arrangementRoomCategories = computed(() => flowStore.checkout.meta.arrangement_choices?.room_products.filter(product => {
  return 'products' in product;
}));

const startDate = computed(() => {
  const stateDate = flowStore.checkout.data.arrival.date;
  return parse(stateDate ? stateDate : format(new Date(), 'yyyy-MM-dd'), 'yyyy-MM-dd', new Date());
});

const endDate = computed(() => {
  const stateDate = flowStore.checkout.data.departure.date;
  if (stateDate) return parse(stateDate, 'yyyy-MM-dd', new Date());
  return null;
});

const endDateIsAfterStart = computed(() => {
  if (startDate.value && endDate.value && startDate.value) {
    return isAfter(endDate.value, startDate.value);
  }
  return false;
});

function changeCalendarDays(arrivalDate: string, departureDate: string | null) {
  flowStore.setCheckoutDate(arrivalDate, departureDate);
}

function mergeCalendarData(data: CalendarResource) {
  calendarData.value = {
    ...calendarData.value,
    ...Object.fromEntries(Object.entries(data.dates)
    .map(([key, options]) => {
      const classes = [
        options.status === 'swimwear' ? 'swimwear-day' : '',
        options.status === 'special' ? 'special-day' : '',
      ];

      return [key, {
        inactive: !options.available,
        price: options.price,
        date: options.date,
        status: options.status,
        classes: classes.filter(e => e)
        .join(' '),
        notifications: options.notifications,
      }];
    })),
  };
}

async function loadCalendarData(year: number, month: number) {
  // Don't await this, we want to load data front the next month in the background.
  prefetchCalendarData(year, month);

  const data = await api.calendar.get(flowStore.checkout.data.checkout_id, year, month);
  if (data) mergeCalendarData(data);

  showSwimwearDayInfo.value = Object.entries(data.dates)
  .some(([key, options]) => options.status === 'swimwear');
}

async function prefetchCalendarData(year: number, month: number) {
  const nextMonth = (month + 1 > 12 ? 1 : month + 1);
  const data = await api.calendar.get(flowStore.checkout.data.checkout_id, year, nextMonth, 'prefetch');
  if (data) mergeCalendarData(data);
}

onMounted(() => {
  loadCalendarData(startDate.value.getFullYear(), startDate.value.getMonth() + 1);
});

// Change the main flow product and reload calendar data.
const changeMainProduct = async (product_id: number) => {
  await flowStore.changeMainProduct(product_id);
  await loadCalendarData(startDate.value.getFullYear(), startDate.value.getMonth() + 1);
};

function moveToElement(element: string) {
  document.querySelector(element)?.scrollIntoView({behavior: 'smooth', block: 'center'})
}
</script>
