<template>
  <div class="flex flex-col gap-6">
    <HotelRoomCategoryChoiceCard v-for="category in props.categories" :key="category.id" :category="category" />
  </div>
</template>

<script lang="ts" setup>
  import type { HotelRoomCategoryResource } from '@/api/resources';
  import HotelRoomCategoryChoiceCard from "@/components/HotelRoomCategoryChoiceCard.vue";

  export interface Props {
    personsCount: number,
    categories: HotelRoomCategoryResource[],
  }

  const props = defineProps<Props>();
</script>
